import { AttachmentModel } from './../AddRequest/AttachmentModel';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ValidationService from '../../Services/ValidationService';
import WithRender from './ConnectingSenderName.html';
import { Inject } from 'vue-di-container';
import ConnectingSenderNameService from './ConnectingSenderNameService';
import { Toaster } from '../../Services/toast';
import { DropdownModel, ProviderModel } from '../Provider/ProviderModel';
import { SenderType } from '../../shared/Enums/SenderType';
import CITCKeywordsService from '../CITCKeywords/CITCKeywordsService';
import ProviderService from '../Provider/ProviderService';
import UserService from '../../shared/userService/UserService';
import { ConnectingSenderNameModel, SenderViewModel } from './ConnectingSenderNameModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ConnectingSenderNameService,
        CITCKeywordsService,
        ProviderService,
    ],
})
export default class ConnectingSenderName extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(ConnectingSenderNameService) public connectingSenderNameService!: ConnectingSenderNameService;
    @Inject(CITCKeywordsService) public cITCKeywordsService!: CITCKeywordsService;
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(UserService) public userService!: UserService;
    @Prop({ type: Boolean, default: false })

    public layoutSup: boolean | any;
    public minDate: Date = new Date();
    public stepNumber: number = 1;
    public senderName: string = '';
    public typeFile: number = 0;
    public senderNameLength: number = 11;
    public contractAttachmentName: string = '';
    public senderType: number[] = [];
    public expiryDateFromMenu: boolean = false;
    public sendersList: string[] = [];
    public submitRequestdialog: boolean = false;
    public isCriticalSender: boolean = false;
    public senderNameId: number = 0;
    public headers: any = [
        { text: this.$t('shared.senderName'), value: 'operatorId', align: 'right', sortable: false },
        { text: this.$t('shared.senderType'), value: 'operatorId', align: 'center', sortable: false },
        { text: this.$t('shared.clientName'), value: 'name', align: 'center', sortable: false }
    ];
    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];

    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];

    public model: ConnectingSenderNameModel = {
        duration: '1',
        startDate: '',
        clientName: '',
        customerType: 0,
        crNumber: '',
        enterpriseUnifiedNumber: '',
        senderNames: [],
        contractAttachment: null,
        requestType: 7,
        comment: '',
        expiryDate: '',

    };

    senderNames: SenderViewModel[] = [];
    public selected: SenderViewModel[] = [];
    public selectedIds: string[] = [];


    public async created() {
        this.$emit('childinit', this.layoutSup);
        let user = await this.userService.getUser();
        let providerId = user.details.ProviderId;
        let provider = await this.providerService.GetById(providerId);
        if (!provider.hasInternational) {
            this.customerTypeList.splice(3, 1);
        }
    }

    public next() {
        if (this.stepNumber < 3) {
            this.stepNumber = this.stepNumber + 1;
        }
    }

    public get isValidApply(): boolean {
        var uinfiedValid = (this.model.customerType == 2 || this.model.customerType == 3) ?
            this.model.enterpriseUnifiedNumber !== '' : this.model.enterpriseUnifiedNumber === '';
        return (this.model.customerType !== 0 &&
            this.model.crNumber !== '' && uinfiedValid);
    }

    public async apply() {
        const response = await this.connectingSenderNameService
            .ConnectingSenderName(this.senderName, this.model.crNumber, this.model.customerType, this.model.enterpriseUnifiedNumber
            );
        if (response.isSuccess) {
            this.senderNames = response.result;
            this.senderNames.forEach(name => {
                name.senderTypes = [];
                this.getSenderType(name.senderType, name)
            });
            this.selected = [];
            this.selectedIds = [];
        } else {
            Toaster.error(response.errorMessage);
        }
    }

    public back() {
        this.stepNumber = this.stepNumber - 1;
    }

    @Watch('model.customerType', { deep: true })
    public onchangeCustomerType() {
        this.model.crNumber = '';
        this.model.senderNames = [];
        this.model.enterpriseUnifiedNumber = '';
        this.senderNames = [];
        switch (this.model.customerType) {
            case 1:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                    {
                        id: SenderType.Warning,
                        name: this.$t('shared.warning').toString(),
                    },
                ];
                this.senderType = [];
                this.senderName = '';
                this.model.senderNames = [];
                break;
            case 2:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                this.senderType = [];
                this.senderName = '';
                this.model.senderNames = [];
                break;
            case 3:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                this.senderType = [];
                this.senderName = '';
                this.model.senderNames = [];
                break;
            case 4:
                this.senderTypeList = [
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                ];
                this.senderType = [SenderType.Service];
                this.senderName = '';
                this.model.senderNames = [];
                break;
        }
    }


    public showsubmitRequestDialog(id: string) {
        this.submitRequestdialog = true;
    }

    public hidesubmitRequestDialog() {
        this.submitRequestdialog = false;
    }

    public async submitRequest() {
        this.model.senderNames = this.selected;
        this.model.clientName = this.selected[0].clientName;
        const response = await this.connectingSenderNameService.post(this.model);
        if (response.isSuccess) {
            this.connectingSenderNameService.addContractRequestEmail(response.result);
            this.stepNumber = 4;
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hidesubmitRequestDialog();
    }

    public newRequest() {
        this.reset();
        this.stepNumber = 1;
    }

    public selectAll() {
        if (this.selected.length) {
            this.selected = [];
            this.selectedIds = [];
        } else {
            this.selected = this.senderNames.slice();
            this.selectedIds = this.senderNames.map((x) => x.id);
        }
    }

    public select(event: any, id: string) {
        if (!event) {
            const index = this.selectedIds.indexOf(id);
            this.selectedIds.splice(index, 1);
        } else {
            this.selectedIds.push(id);
        }
    }


    public get isValidSenderName(): boolean {
        return (this.senderName.trim() !== '' && this.senderName.length <= 11 && this.senderType.length !== 0);
    }

    public deleteContract() {
        this.model.contractAttachment = null;
        this.contractAttachmentName = '';
        (this.$refs.contractAttachment as any).value = '';
    }

    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }

    public getSenderType(type: number, sender: SenderViewModel) {
        sender.senderTypes = [];
        const senderBinary = type;
        if ((senderBinary & 1) === 1) {
            sender.senderTypes.push(1);
        }
        if ((senderBinary & 2) === 2) {
            sender.senderTypes.push(2);
        }
        if ((senderBinary & 4) === 4) {
            sender.senderTypes.push(4);
        }
        if ((senderBinary & 8) === 8) {
            sender.senderTypes.push(8);
        }
    }

    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }

    public onPickFile(type: number) {
        this.typeFile = type;

        (this.$refs.contractAttachment as any).click();

    }

    public onFilePicked(event: any) {
        const files: any[] = event.target.files;
        const modelArray: AttachmentModel = {} as AttachmentModel;
        Array.prototype.forEach.call(files, (file, index) => {
            modelArray.fileName = file.name;
            if (file.size > 3000000) {
                Toaster.error(this.$t('shared.fileSizeExceedsTheLimit').toString());
            }
            else {
                this.contractAttachmentName = file.name;
                const reader = new FileReader();
                reader.onloadend = (fileData: any) => {
                    modelArray.fileContent = fileData.target.result.split(',')[1];
                    modelArray.attachmentType = this.typeFile;
                    this.model.contractAttachment = modelArray;
                };
                reader.readAsDataURL(file);
            }
        });
    }

    public get isValidStep(): boolean {
        if (this.stepNumber === 1) {
            var uinfiedValid = (this.model.customerType == 2 || this.model.customerType == 3) ?
                this.model.enterpriseUnifiedNumber !== '' : this.model.enterpriseUnifiedNumber === '';
            return (
                this.model.customerType !== 0 &&
                this.model.crNumber !== '' &&
                uinfiedValid
                && this.selectedIds.length !== 0
            );
        }
        else {
            return (
                this.model.expiryDate !== "" &&
                new Date(this.model.expiryDate) > this.minDate &&
                this.model.contractAttachment != null && this.model.contractAttachment.attachmentType === 1);
        }

    }

    public reset() {
        this.model = {
            duration: '1',
            startDate: '',
            clientName: '',
            crNumber: '',
            enterpriseUnifiedNumber: '',
            customerType: 0,
            senderNames: [],
            contractAttachment: null,
            requestType: 7,
            comment: '',
            expiryDate: ''
        };
        this.contractAttachmentName = '';
        this.senderType = [];
    }
}