<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.request')}} #{{id}}
                    </label>
                </div>
                <div class="col-auto">
                    <router-link v-if="(userRole == 2 ||userRole == 5) && sender.requestStatus == 3" :to="'/Dashboard/EditSender/' + id" tag="button" class="btn btn-success px-4">{{$t('shared.edit')}}</router-link>
                    <v-menu offset-y open-on-hover>
                        <template v-slot:activator="{ on }">
                            <v-btn color="btn-primary"
                                   v-on="on">
                                <div>{{$t('shared.actionHere')}}</div>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-tile v-if="(userRole == 2 ||userRole == 5) && sender.requestStatus == 2" @click="PreparePay()">
                                <v-list-tile-title class="white">{{$t('dashboardPage.Pay')}}</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile v-if="(userRole == 1  || userRole == 9) && sender.requestStatus == '1' && ((userRole == 1 && sender.tenantId == tenantId) || userRole != 1) " @click="showapproveDialog()">
                                <v-list-tile-title class="white">{{$t('dashboardPage.Approve')}}</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile v-if="(userRole == 1  || userRole == 9) && ((userRole == 1 && sender.tenantId == tenantId) || userRole != 1) && (sender.requestStatus == '4' || sender.requestStatus == '5'|| sender.requestStatus == '6') && (sender.senderStatus != 4)" @click="showDeactivateDialog()">
                                <v-list-tile-title class="white">{{$t('dashboardPage.deactivate')}}</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile v-if="((userRole == 2 ||userRole == 5) && !providerLocked) && sender.requestStatus == 3" @click="showresendDialog()">
                                <v-list-tile-title class="white">{{$t('dashboardPage.resend')}}</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile v-if="(userRole == 1  || userRole == 9) && ((userRole == 1 && sender.tenantId == tenantId) || userRole != 1) && sender.requestStatus == '1'" @click="showRejectDialog()">
                                <v-list-tile-title class="white">{{$t('dashboardPage.reject')}}</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile v-if=" (userRole == 1  || userRole == 9) && ((userRole == 1 && sender.tenantId == tenantId) || userRole != 1)  && (sender.requestStatus == '4' || sender.requestStatus == '2')" @click="showDeleteDialog(sender.id)">
                                <v-list-tile-title class="white">{{$t('shared.delete')}}</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile v-if=" (userRole == 2 ||userRole == 5) && (sender.requestStatus == 4 || sender.requestStatus == 5)" @click="showExtendRequests()">
                                <v-list-tile-title class="white">{{$t('shared.showExtendRequest')}}</v-list-tile-title>
                            </v-list-tile>
                            <v-list-tile @click="showSenderRequestTransactions()">
                                <v-list-tile-title class="white">{{$t('shared.showSenderRequestActions')}}</v-list-tile-title>
                            </v-list-tile>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div v-if="sender.isScamSender &&(userRole == 1 || userRole == 3
                                                        || userRole == 4|| userRole == 9)" class="col-md-9">
                            <v-icon x-large style="color:red">warning_amber</v-icon><span style="color: red; top: -10px; position: relative;">Warning : its possible that The sender name may be considered suspecious </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('dashboardPage.senderName')}}</label>
                                    <h4 class="user_info_txt">{{sender.senderName}}</h4>
                                </div>
                                <div class="col-sm-6 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.senderType')}}</label>
                                    <h4 class="user_info_txt">
                                        <span v-for="(type, index) in senderType" :key="type">
                                            {{getSenderTypeText(type)}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="col-sm-4 mb-3" v-if="(userRole != 2  && userRole != 5)">
                                    <label class="user_info_header mb-0">{{$t('shared.providerName')}}</label>
                                    <h4 class="user_info_txt">{{sender.providerName}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.clientName')}}</label>
                                    <h4 class="user_info_txt">{{sender.clientName}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0" v-if="sender.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                                    <label class="user_info_header mb-0" v-if="sender.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                    <label class="user_info_header mb-0" v-if="sender.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                    <label class="user_info_header mb-0" v-if="sender.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                    <h4 class="user_info_txt no-collapse">{{sender.crNumber}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3" v-if="sender.customerType === 2 ||sender.customerType === 3">
                                    <label class="user_info_header mb-0">{{$t('shared.enterpriseUnifiedNumber')}}</label>
                                    <h4 class="user_info_txt no-collapse">{{sender.enterpriseUnifiedNumber}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.customerType')}}</label>
                                    <h4 class="user_info_txt">{{getCustomerTypeText(sender.customerType)}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.representativeName')}}</label>
                                    <h4 class="user_info_txt">{{sender.representativeName}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.apEmail')}}</label>
                                    <h4 class="user_info_txt">{{sender.representativeEmail}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.phoneNumber')}}</label>
                                    <h4 v-if="sender.customerType === 1 ||sender.customerType === 2 ||sender.customerType === 3" class="user_info_txt"><span class="pr-3">+966</span> {{sender.representativeMobile}}</h4>
                                    <h4 v-if="sender.customerType === 4" class="user_info_txt">{{sender.representativeMobile}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.duration')}}</label>
                                    <h4 class="user_info_txt">{{sender.duration}} {{$t('shared.year')}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.senderCriticality')}}</label>
                                    <h4 v-if="sender.isCriticalSender" class="user_info_txt">{{$t('shared.critical')}}</h4>
                                    <h4 v-if="!sender.isCriticalSender" class="user_info_txt">{{$t('shared.notCritical')}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.contractExpiryDate')}}</label>
                                    <h4 class="user_info_txt">{{sender.expiryDate}}</h4>
                                </div>
                                <div class="col-md-12" v-if="sender.requestStatus == 3">
                                    <label class="user_info_header mb-3">{{$t('shared.rejectionReason')}}</label>
                                    <textarea rows="3" class="form-control" style="resize: none" v-model="sender.lastRejectionReason" readonly></textarea>
                                </div>
                                <div class="col-md-12">
                                    <label>{{$t('shared.attachmentList')}}</label>
                                    <ul class="list-group list_Sender scrollbar">
                                        <li class="list-group-item" v-if="sender.senderRequestAttachments.length > 0" v-for="(item, index) in sender.senderRequestAttachments" :key="item">
                                            <lable class="box-name w-100">{{item.fileName}}</lable>
                                            <lable class="box-name w-100">{{item.attachmentTypeName}}</lable>
                                            <i class="fas fa-download" @click="DownloadFile(item)"></i>
                                        </li>
                                        <li class="list-group-item" v-if="sender.senderRequestAttachments.length == 0">
                                            {{$t('shared.noAttachments')}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <Progress :transitionDuration="1000" :radius="85" :strokeColor="checkColor(sender.requestStatus)" :strokeWidth="10" :value="getRate(sender.startDate, sender.duration)">
                                <div class="content">
                                    <label class="w-100" :class="'status-' + sender.requestStatus">{{GetSenderStatus(sender.requestStatus)}}</label>
                                    <p>
                                        {{sender.remainingDays}} {{$t('dashboardPage.of')}} {{sender.totalDays}} <br /> {{$t('dashboardPage.daysLeft')}}
                                    </p>
                                </div>
                            </Progress>
                        </div>
                        <div class="col-md-12 mt-5">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto mx-3">
                                    <router-link to="/Dashboard" tag="a" class="link_secondary">{{$t('shared.back')}}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="showSenderRequestTransactionsdialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.SenderRequestActionsInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideshowSenderRequestTransactionsdialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <template>
                        <v-data-table :headers="headers"
                                      :items="SenderRequestTransactions"
                                      hide-actions
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.requestStatus }}</td>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                    <td class="text-center">{{ props.item.comment }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="showExtendRequestsdialog" width="600">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.extendRequest')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideExtendRequestsdialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <template>
                        <v-data-table :headers="extendRequestHeaders"
                                      :items="senderRequestExtendViewModel"
                                      hide-actions
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.extendRequestId }}</td>
                                    <td class="text-center">{{ props.item.duration }}</td>
                                    <td class="text-center">{{ props.item.requestStatus }}</td>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="resendDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.resendInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideresendDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.comment')}}</label>
                                <input v-model="resendComment" class="form-control " type="text" />
                            </div>
                        </div>
                    </div>
                    <p class="mt-3">
                        {{$t('shared.resendInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideresendDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="resend('')">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="approveDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.approveInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideapproveDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.comment')}}</label>
                                <input v-model="approveComment" class="form-control " type="text" />
                            </div>
                        </div>
                    </div>
                    <p class="mt-3">
                        {{$t('shared.approveInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideapproveDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="approve('')">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="deactivateDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.deactivateInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDeactivateDialog()"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.deactivateInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideDeactivateDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="deactivate">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="deleteDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.deleteInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDeleteDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text">
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.comment')}}</label>
                                <input v-model="deleteComment" class="form-control " type="text" />
                            </div>
                        </div>
                    </div>
                    <p class="mt-3">
                        {{$t('shared.deletesenderInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideDeleteDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="deleteSender">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="rejectdialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('dashboardPage.reject')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideRejectDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <v-select :label="$t('dashboardPage.rejectreason')"
                                      :items="popUpRejectionReasons"
                                      return-object
                                      multiple
                                      item-value="id"
                                      v-model="selectedRejectReasonList"
                                      item-text="rejectionText">
                                <template v-slot:prepend-item>
                                    <v-list-tile ripple
                                                 @click="selectAllToggle">
                                        <v-list-tile-action>
                                            <i aria-hidden="true" class="v-icon material-icons theme--light">{{ icon }}</i>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title>{{$t('shared.selectAll')}}</v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider class="mt-2"></v-divider>
                                    <v-list-tile ripple
                                                 @click="SelectOtherToggle">
                                        <v-list-tile-action>
                                            <i aria-hidden="true" class="v-icon material-icons theme--light">{{ OtherIcon }}</i>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            <v-list-tile-title>{{$t('shared.other')}}</v-list-tile-title>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-select>
                            <div class="form-group" v-if="isItOtherReason">
                                <v-textarea clearable
                                            clear-icon="mdi-close-circle"
                                            bg-color="#dee2e6  !important"
                                            no-resize
                                            maxlength="80"
                                            rows="3"
                                            :label="$t('dashboardPage.rejectreason')"
                                            required
                                            @display=""
                                            :rules="rules"
                                            variant="filled"
                                            v-model="otherRejectResone">
                                </v-textarea>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideRejectDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" :disabled="!rejectReasonIsValid" @click="reject">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>