import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './Layout.html';
import moment from 'moment';
import UserService from '../../shared/userService/UserService';
import UserModel from '../../shared/userService/Model/UserModel';
import TokenService from '../../Services/TokenService';
import { Toaster } from '../../Services/toast';
import LoginService from '../Login/LoginService';
import UserRoleService from '../../shared/userService/UserRoleService';

@WithRender
@Component({
    diProvide: [
        UserService,
        LoginService,
        UserRoleService,
    ],
})
export default class Layout extends Vue {
    @Inject(UserService) public userService!: UserService;
    @Inject(TokenService) public tokenService!: TokenService;
    @Inject(LoginService) public logInService!: LoginService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    public userName: string = '';
    public password: string = '';
    public isDropdownShow: boolean = false;
    public isReportDropdownShow: boolean = false;
    public layoutSup: boolean = false;
    public logOutDialog: boolean = false;
    public loginDialog: boolean = false;
    public showPassword: boolean = false;
    public otpForm: boolean = false;
    public logInForm: boolean = true;
    public collapsed: boolean = false;
    public data: string = '';
    public otpExpiresAt: string = '';
    public otp: string = '';
    public userRole: any;
    public countDown: number = 0;
    public themeCompany: any = 'Default';
    public logoLayoutSrc: any = 'JabberLogo';
    public errorUserCertificate: boolean = false;
    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            TenantId: '',
            ProviderId: '',
            Role: '',
            ChangePasswordRequired: '',
        },
    };

    public created() {
        if (process.env.VUE_APP_TITLE !== undefined && process.env.VUE_APP_LogoLayoutSrc !== undefined) {
            this.themeCompany = process.env.VUE_APP_TITLE.toString();
            this.logoLayoutSrc = process.env.VUE_APP_LogoLayoutSrc.toString();
        } else {
            this.themeCompany = 'Default';
            this.logoLayoutSrc = 'JabberLogo';
        }
        this.countDownTimer();
    }

    public async mounted() {
        this.user = await this.userService.getUser();
    }

    public collapse() {
        this.collapsed = !this.collapsed;
    }

    public onChildInit(value: boolean) {
        this.layoutSup = value;
    }

    public get currentYear() {
        return moment().year();
    }

    public showLogOutDialog() {
        this.logOutDialog = true;
    }

    public hideLogOutDialog() {
        this.logOutDialog = false;
    }

    public async logOut() {
        await this.tokenService.setToken('');
        this.logOutDialog = false;
        this.$router.push({ name: 'Login' });
    }

    public countDownTimer() {
        const currentDate = moment().format('lll');
        if (!moment(currentDate).isAfter(localStorage.expiresIn)) {
            setTimeout(() => {
                this.countDownTimer();
                localStorage.timer -= 1;
                if (localStorage.timer === '900') {
                    Toaster.warning('15 minutes left for your login to expire', this.showLoginDialog);
                }
            }, 1000);
        } else {
            this.logOut();
        }
    }

    public showLoginDialog() {
        this.userName = '';
        this.otp = '';
        this.password = '';
        this.loginDialog = true;
    }

    public hideLoginDialog() {
        this.loginDialog = false;
        this.logInForm = true;
        this.otpForm = false;
    }

    public get GetUserRole() {
        return this.userRoleService.GetUserRole(this.user);
    }
    public get isValid(): boolean {
        return (this.userName.trim() !== '' && this.password.trim() !== '');
    }

    public async login() {
        if (this.user.username.toLowerCase() === this.userName.toLowerCase()) {
            const result = await this.logInService.login(this.userName, this.password,"");
            if (result.isSuccess && result.isOTPEnabled) {
                this.data = result.data;
                this.otpExpiresAt = result.expiresAt;
                this.countDown = result.duration;
                this.switchToOtpForm();
                this.countDownTimerOTP();
            } else if (result.isSuccess && !result.isOTPEnabled) {
                await this.tokenService.setToken(result.data);
                const expiresIn = parseInt(result.duration, 0);
                localStorage.timer = expiresIn * 60;
                localStorage.expiresIn = moment().add(expiresIn, 'minutes').format('lll');
                this.userRole = this.GetUserRole;
                if (this.userRole === 8) {
                    window.location.href = '/Providers';
                } else {
                    window.location.href = '/Dashboard';
                }
                this.loginDialog = false;
            } else {
                this.loginDialog = false;
                Toaster.error(result.errorMessage);
            }
        } else {
            this.errorUserCertificate = true;
        }
    }

    public switchToOtpForm() {
        this.logInForm = false;
        this.otpForm = true;
    }

    public countDownTimerOTP() {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1;
                this.countDownTimerOTP();
            }, 1000);
        }
    }

    public async resendOtp() {
        const result = await this.logInService.resendOTP(this.data);
        if (result.isSuccess) {
            this.data = result.data;
            this.otpExpiresAt = result.expiresAt;
            this.countDown = result.duration;
            this.countDownTimerOTP();
        } else {
            Toaster.error(result.errorMessage);
        }
    }

    public async verfiyOTP() {
        const result = await this.logInService.verfiyOTP(this.data, this.otpExpiresAt, this.otp);
        if (result.isSuccess) {
            await this.tokenService.setToken(result.token);
            const expiresIn = parseInt(result.expiresIn, 0);
            localStorage.timer = expiresIn * 60;
            localStorage.expiresIn = moment().add(expiresIn, 'minutes').format('lll');
            this.loginDialog = false;
            this.logInForm = true;
            this.otpForm = false;
        } else {
            this.loginDialog = false;
            this.logInForm = true;
            this.otpForm = false;
            Toaster.error(result.errorMessage);
        }
    }

    public get isResendValid(): boolean {
        return (this.countDown > 0);
    }
}
