import Vue from 'vue';
import Router from 'vue-router';
import jwt_decode from 'jwt-decode';
import AddProvider from './components/Provider/AddProvider/AddProvider';
import AddRequest from './components/AddRequest/AddRequest';
import AddShortCode from './components/Short/AddShortCode/AddShortCode';
import Dashboard from './components/Dashboard/Dashboard';
import EditSender from './components/Senders/EditSender/EditSender';
import Layout from './components/Layout/Layout';
import Payment from './components/Payment/Payment';
import Provider from './components/Provider/Provider';
import SenderInfo from './components/Senders/SenderInfo/SenderInfo';
import Short from './components/Short/Short';
import UserInfo from './components/UserManagement/UserInfo/UserInfo';
import UserManagement from './components/UserManagement/UserManagement';
import UserRegistration from './components/UserManagement/UserRegistration/UserRegistration';
import LoginUserName from './components/LoginUserName/LoginUserName';
import Login from './components/Login/Login';
import PaymentResult from './components/Payment/PaymentResult/PaymentResult';
import AddProviderRequest from './components/AddProviderRequest/AddProviderRequest';
import ProviderRequests from './components/ProviderRequests/ProviderRequests';
import ProviderRequestInfo from './components/ProviderRequests/ProviderRequestInfo/ProviderRequestInfo';
import Report from './components/Report/Report';
import CITCKeywords from './components/CITCKeywords/CITCKeywords';
import AddCITCKeywords from './components/CITCKeywords/AddCITCKeywords/AddCITCKeywords';

import AttachmentCategory from './components/AttachmentCategory/AttachmentCategory';
import AddAttachmentCategory from './components/AttachmentCategory/AddAttachmentCategory/AddAttachmentCategory';

import ChangeSenderType from './components/Senders/ChangeSenderType/ChangeSenderType';
import UpdateRequestData from './components/UpdateRequestData/UpdateRequestData';
import ChangeSenderTypeRequests from './components/Dashboard/ChangeSenderTypeRequests/ChangeSenderTypeRequests';
import UpdateDataRequests from './components/Dashboard/UpdateDataRequests/UpdateDataRequests';
import SenderConectivitySupport from './components/SenderConectivitySupport/SenderConectivitySupport';
import RenewRequest from './components/RenewRequest/RenewRequest';
import Invoices from './components/Invoice/Invoices';
import PublicPayment from './components/PublicPayment/PublicPayment';
import TrackRequest from './components/TrackRequest/TrackRequest';
import CreditLimitTransaction from './components/CreditLimitTransaction/CreditLimitTransaction';
import ProvidersFinance from './components/ProvidersFinance/ProvidersFinance';
import EditProvidersFinance from './components/ProvidersFinance/EditProvidersFinance/EditProvidersFinance';
import BalanceInvoice from './components/BalanceInvoice/BalanceInvoice';
import SupportPaymentTransaction from './components/SupportPaymentTransaction/SupportPaymentTransaction';
import SenderRequestSupport from './components/SenderRequestSupport/SenderRequestSupport';
import SenderRequestApprovalHistory from './components/SenderRequestSupport/SenderRequestApprovalHistory/SenderRequestApprovalHistory';
import SenderRequestPaymentTransactions from './components/SenderRequestSupport/SenderRequestPaymentTransactions/SenderRequestPaymentTransactions';
import SLA from './components/SLA/SLA';
import MonitorDashboard from './components/MonitorDashboard/MonitorDashboard';
import ConnectivityActivationReport from './components/ConnectivityActivationReport/ConnectivityActivationReport';
import ExtendDurationRequests from './components/Dashboard/ExtendDurationRequests/ExtendDurationRequests';
import RenewRegisterSender from './components/Dashboard/ExtendDurationRequests/RenewRegisterSender/RenewRegisterSender';
import GetInvoice from './components/GetInvoice/GetInvoice';
import ConnectingSenderName from './components/ConnectingSenderName/ConnectingSenderName';
import ConnectingRequestDashboard from './components/ConnectingRequestDashboard/ConnectingRequestDashboard';
import ContractConnectivityDetail from './components/ContractConnectivityDetails/ContractConnectivityDetail';
import RenewContractRequest from './components/RenewContractReques/RenewContractRequest';
import UpdateContractRequest from './components/ConnectingSenderName/UpdateContractRequest/UpdateContractRequest';
import ContractRequestInfo from './components/ConnectingRequestDashboard/ContractRequestInfo/ContractRequestInfo';
import SenderConectivityDetail from './components/SenderConectivityDetail/SenderConectivityDetail';
import SenderRequestStatusDashboard from './components/SenderRequestStatusDashboard/SenderRequestStatusDashboard';
import AlertsDashboard from './components/AlertsDashboard/AlertsDashboard';
import ScamList from './components/ScamManagement/ScamList';
import AddScamList from './components/ScamManagement/AddScamList/AddScamList';
import RejectionReasonList from './components/RejectionReason/RejectionReasonList';
Vue.use(Router);
let fromDashboard: boolean;
export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/Login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/AddProviderRequest',
            name: 'AddProviderRequest',
            component: AddProviderRequest,
        },
        {
            path: '/PublicPayment',
            name: 'PublicPayment',
            component: PublicPayment,
        },
        {
            path: '/PaymentResult',
            name: 'PaymentResult',
            component: PaymentResult,
            props: (route) => ({ isSuccess: route.params.isSuccess }),
        },
        {
            path: '/LoginUserName',
            name: 'LoginUserName',
            component: LoginUserName,
        },
        {
            path: '/TrackRequest',
            name: 'TrackRequest',
            component: TrackRequest,
        },
        {
            path: '/GetInvoice',
            name: 'GetInvoice',
            component: GetInvoice,
        },
        {
            path: '/',
            name: 'Layout',
            component: Layout,
            redirect: {
                name: 'Dashboard',
            },
            beforeEnter: (to, from, next) => {
                if (localStorage.token) {
                    next();
                } else {
                    next({ name: 'Login' });
                }
            },
            children: [
                {
                    path: '/Dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Finance') {
                            next({ name: 'Invoices' });
                        }
                        else if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Monitor') {
                            next({ name: 'MonitorDashboard' });
                        }
                        else if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            !== 'Super Admin User' && decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            !== 'Support') {
                            next();
                        } else if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User') {
                            next({ name: 'UserManagement' });
                        } else if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Support') {
                            next({ name: 'SenderConectivitySupport' });
                        }
                        else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: false }),
                },
                {
                    path: '/ProvidersFinance',
                    name: 'ProvidersFinance',
                    component: ProvidersFinance,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Support') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/SenderRequestSupport/SenderRequestApprovalHistory/:id',
                    name: 'SenderRequestApprovalHistory',
                    component: SenderRequestApprovalHistory,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Support') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                },
                {
                    path: '/SenderRequestSupport/SenderRequestPaymentTransactions/:id',
                    name: 'SenderRequestPaymentTransactions',
                    component: SenderRequestPaymentTransactions,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Support') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                },
                {
                    path: 'ProvidersFinance/EditProvidersFinance/:id',
                    name: 'EditProvidersFinance',
                    component: EditProvidersFinance,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Support') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/ChangeSenderTypeRequests',
                    name: 'ChangeSenderTypeRequests',
                    component: ChangeSenderTypeRequests,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            !== 'Super Admin User') {
                            next();
                        } else if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User') {
                            next({ name: 'UserManagement' });
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Dashboard/SenderConectivityDetail/:senderId',
                    name: 'SenderConectivityDetail',
                    component: SenderConectivityDetail,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ senderId: route.params.senderId, layoutSup: true }),
                },
                {
                    path: '/UpdateDataRequests',
                    name: 'UpdateDataRequests',
                    component: UpdateDataRequests,
                    beforeEnter: (to, from, next) => {
                        next({ name: 'Login' });
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/ExtendDurationRequests',
                    name: 'ExtendDurationRequests',
                    component: ExtendDurationRequests,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/ExtendDurationRequests/RenewRegisterSender',
                    name: 'RenewRegisterSender',
                    component: RenewRegisterSender,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Dashboard/AddRequest',
                    name: 'AddRequest',
                    component: AddRequest,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Dashboard/RenewRequest/:id',
                    name: 'RenewRequest',
                    component: RenewRequest,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true }),
                },
                {
                    path: '/Dashboard/EditSender/:id',
                    name: 'EditSender',
                    component: EditSender,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true }),
                },
                {
                    path: '/Dashboard/SenderInfo/:id',
                    name: 'SenderInfo',
                    component: SenderInfo,
                    beforeEnter: (to, from, next) => {
                        if (from.name === 'Dashboard' || from.name === 'EditSender') {
                            fromDashboard = true;
                        } else {
                            fromDashboard = false;
                        }
                        next();
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true, isFromDashboard: fromDashboard }),
                },
                {
                    path: '/Dashboard/ChangeSenderType/:id',
                    name: 'ChangeSenderType',
                    component: ChangeSenderType,
                    beforeEnter: (to, from, next) => {
                        if (from.name === 'Dashboard') {
                            fromDashboard = true;
                        } else {
                            fromDashboard = false;
                        }
                        next();
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true, isFromDashboard: fromDashboard }),
                },
                {
                    path: '/Dashboard/UpdateRequestData/:id',
                    name: 'UpdateRequestData',
                    component: UpdateRequestData,
                    beforeEnter: (to, from, next) => {
                        next({ name: 'Login' });
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true, isFromDashboard: fromDashboard }),
                },
                {
                    path: '/Dashboard/Payment/',
                    name: 'Payment',
                    component: Payment,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true, isFromDashboard: fromDashboard }),
                },
                {
                    path: '/ConnectingRequestDashboard/ContractConnectivityDetail/:senderId',
                    name: 'ContractConnectivityDetail',
                    component: ContractConnectivityDetail,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ senderId: route.params.senderId, layoutSup: true }),
                },
                {
                    path: '/ConnectingRequestDashboard/UpdateContractRequest/:id',
                    name: 'UpdateContractRequest',
                    component: UpdateContractRequest,
                    beforeEnter: (to, from, next) => {
                        if (from.name === 'ConnectingRequestDashboard') {
                            fromDashboard = true;
                        } else {
                            fromDashboard = false;
                        }
                        next();
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true, isFromDashboard: fromDashboard }),
                },
                {
                    path: '/ConnectingRequestDashboard/ContractRequestInfo/:id',
                    name: 'ContractRequestInfo',
                    component: ContractRequestInfo,
                    beforeEnter: (to, from, next) => {
                        if (from.name === 'ConnectingRequestDashboard' || from.name === 'EditSender') {
                            fromDashboard = true;
                        } else {
                            fromDashboard = false;
                        }
                        next();
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true, isFromDashboard: fromDashboard }),
                },
                {
                    path: '/SenderConectivitySupport',
                    name: 'SenderConectivitySupport',
                    component: SenderConectivitySupport,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Support' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC User') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/SenderRequestSupport',
                    name: 'SenderRequestSupport',
                    component: SenderRequestSupport,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Support') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: false }),
                },
                {
                    path: '/SupportPaymentTransaction',
                    name: 'SupportPaymentTransaction',
                    component: SupportPaymentTransaction,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Support') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: false }),
                },
                {
                    path: '/UserManagement',
                    name: 'UserManagement',
                    component: UserManagement,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/CreditLimitTransaction',
                    name: 'CreditLimitTransaction',
                    component: CreditLimitTransaction,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if ((decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' ||
                            decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User' ||
                            decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Finance' ||
                            decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' ||
                            decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin')) {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Report',
                    name: 'Report',
                    component: Report,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin'
                            || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/ConnectivityActivationReport',
                    name: 'ConnectivityActivationReport',
                    component: ConnectivityActivationReport,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin'
                            || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: false }),
                },
                {
                    path: '/CITCKeywords',
                    name: 'CITCKeywords',
                    component: CITCKeywords,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin'
                            || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/CITCKeywords/AddCITCKeywords',
                    name: 'AddCITCKeywords',
                    component: AddCITCKeywords,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/CITCKeywords/AddCITCKeywords/Edit/:id',
                    name: 'AddCITCKeywords_Edit',
                    component: AddCITCKeywords,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ id: route.params.id, mode: 'Edit', layoutSup: true }),
                },
                {
                    path: '/AttachmentCategory',
                    name: 'AttachmentCategory',
                    component: AttachmentCategory,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin'
                            || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/AttachmentCategory/AddAttachmentCategory',
                    name: 'AddAttachmentCategory',
                    component: AddAttachmentCategory,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/AttachmentCategory/AddAttachmentCategory/Edit/:id',
                    name: 'AddAttachmentCategory_Edit',
                    component: AddAttachmentCategory,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ id: route.params.id, mode: 'Edit', layoutSup: true }),
                },
                {
                    path: '/Providers/ProviderRequests',
                    name: 'ProviderRequests',
                    component: ProviderRequests,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Providers/AddProvider',
                    name: 'AddProvider',
                    component: AddProvider,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin') {
                            next({ name: 'Login' });
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/UserManagement/UserRegistration',
                    name: 'UserRegistration',
                    component: UserRegistration,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),

                },
                {
                    path: '/UserManagement/UserRegistration/Edit/:id',
                    name: 'UserRegistration_Edit',
                    component: UserRegistration,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ id: route.params.id, mode: 'Edit', layoutSup: true }),
                },
                {
                    path: '/UserManagement/UserInfo/:id',
                    name: 'UserInfo',
                    component: UserInfo,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true, id: route.params.id }),

                },
                {
                    path: '/Providers/ProviderRequests/ProviderRequestInfo/:id',
                    name: 'ProviderRequestInfo',
                    component: ProviderRequestInfo,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true, id: route.params.id }),
                },
                {
                    path: '/Providers',
                    name: 'Providers',
                    component: Provider,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                }
                , {
                    path: '/ConnectingSenderName',
                    name: 'ConnectingSenderName',
                    component: ConnectingSenderName,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/ConnectingRequestDashboard',
                    name: 'ConnectingRequestDashboard',
                    component: ConnectingRequestDashboard,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin'
                            || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/ConnectingRequestDashboard/RenewRequest/:id',
                    name: 'RenewRequest',
                    component: RenewContractRequest,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true }),
                },
                {
                    path: '/Providers/EditProvider/:id',
                    name: 'EditProvider',
                    component: AddProvider,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ id: route.params.id, mode: 'Edit', layoutSup: true }),
                },
                {
                    path: '/Providers/EditProvider',
                    name: 'MyProfile',
                    component: AddProvider,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' ||
                            decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Shorts',
                    name: 'Shorts',
                    component: Short,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Scams',
                    name: 'Scams',
                    component: ScamList,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/RejectionReason',
                    name: 'RejectionReason',
                    component: RejectionReasonList,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Invoices',
                    name: 'Invoices',
                    component: Invoices,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Finance') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/BalanceInvoice',
                    name: 'BalanceInvoice',
                    component: BalanceInvoice,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Finance') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Shorts/AddShortCode/:id',
                    name: 'AddShortCode',
                    component: AddShortCode,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true }),
                }, {
                    path: '/Shorts/AddShortCode',
                    name: 'AddShortCode',
                    component: AddShortCode,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Provider Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/Scams/AddScamList/:id',
                    name: 'AddScamList',
                    component: AddScamList,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ id: route.params.id, layoutSup: true }),
                }, {
                    path: '/Scams/AddScamList',
                    name: 'AddScamList',
                    component: AddScamList,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'CITC User' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Super Admin' || decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Platform Admin') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/SLA',
                    name: 'SLA',
                    component: SLA,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            !== '') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: '/MonitorDashboard',
                    name: 'MonitorDashboard',
                    component: MonitorDashboard,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Monitor') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: 'SenderRequestStatusDashboard',
                    name: 'SenderRequestStatusDashboard',
                    component: SenderRequestStatusDashboard,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Monitor') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ layoutSup: true }),
                },
                {
                    path: 'AlertsDashboard',
                    name: 'AlertsDashboard',
                    component: AlertsDashboard,
                    beforeEnter: (to, from, next) => {
                        const decodedToken: any = jwt_decode(localStorage.token);
                        if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                            === 'Monitor') {
                            next();
                        } else {
                            next({ name: 'Login' });
                        }
                    },
                    props: (route) => ({ status: route.params.status, layoutSup: true }),
                },
            ],
        },
    ],
});