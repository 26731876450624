import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './SenderRequestPaymentTransactions.html';
import SenderRequestSupportService from '../SenderRequestSupportService';
import { Toaster } from '../../../Services/toast';
import { SenderRequestPaymentTransactionsModel } from './SenderRequestPaymentTransactionsModel';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        SenderRequestSupportService,
    ],
})
export default class SenderRequestApprovalHistory extends Vue {
    @Inject(SenderRequestSupportService) public senderRequestSupportService!: SenderRequestSupportService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public senderRequestPaymentTransactionsModel: SenderRequestPaymentTransactionsModel[] = [];
    public senderRequestPaymentTransactionsCount: number = 0;
    public headers: any = [
        { text: this.$t('shared.amount'), value: 'amount', align: 'center', sortable: false },
        { text: this.$t('shared.paymentStatus'), value: 'paymentStatus', align: 'center', sortable: false },
        {
            text: this.$t('shared.transactionReference'),
            value: 'transactionReference', align: 'center', sortable: false,
        },
        { text: this.$t('shared.checkOutId'), value: 'checkOurId', align: 'center', sortable: false },
        { text: this.$t('shared.creditLimitPayment'), value: 'isCreditLimitPayment', align: 'center', sortable: false },
        { text: this.$t('shared.isMada'), value: 'isMada', align: 'center', sortable: false },
        { text: this.$t('shared.userId'), value: 'userId', align: 'center', sortable: false },
        { text: this.$t('shared.createdDate'), value: 'createdDate', align: 'center', sortable: false },
        { text: this.$t('shared.lastModifiedDate'), value: 'lastModifiedDate', align: 'center', sortable: false },
    ];
    public id!: string;
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.id = this.$route.params.id;
        const response = await this.senderRequestSupportService.getPaymentTransactionByRequestid(this.id, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            this.senderRequestPaymentTransactionsModel = response.result;
            this.senderRequestPaymentTransactionsCount = response.totalCount;
        }
    }
    public async updatePaginate(val: any) {
        const response = await this.senderRequestSupportService.getPaymentTransactionByRequestid(this.id,
            (val.page - 1).toString(), val.rowsPerPage);
        this.senderRequestPaymentTransactionsModel = response.result;
        this.senderRequestPaymentTransactionsCount = response.totalCount;
    }
}
