<div class="layout_page w-100 h-100 scrollbar" id="scrollbar" :class="{'layout_Sup': layoutSup}">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light px-0">
            <router-link class="navbar-brand" to="/Dashboard" v-if="GetUserRole !== 8 ">
                <img :src="require( `../img/${logoLayoutSrc}.svg`)" width="150" />
            </router-link>
            <button class="navbar-toggler" type="button" @click="collapse">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" :class="{'show': collapsed}" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <router-link to="/Dashboard" v-if="GetUserRole !== 6 && GetUserRole !== 8 && GetUserRole !== 10"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.dashboard')}}</a>
                        </li>
                    </router-link>
                    <li class="nav-item dropdown custom-dropdown" v-if="GetUserRole === 2 ||GetUserRole === 5">
                        <a class="nav-link dropdown-toggle" :class="{'show': isDropdownShow}" @click="isDropdownShow = !isDropdownShow" href="javascript:void(0);" id="navbarDropdown" role="button" data-bs-toggle="dropdown" :aria-expanded="isDropdownShow">
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" :class="{'show': isDropdownShow}" aria-labelledby="navbarDropdown">
                            <li>
                                <router-link v-if="GetUserRole == 2 || GetUserRole == 5" to="/ExtendDurationRequests" @click.native="isDropdownShow = !isDropdownShow" tag="a" class="dropdown-item">
                                    {{$t('shared.extendDurationRequests')}}
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <router-link to="/ConnectingRequestDashboard" v-if="GetUserRole === 1 || GetUserRole === 2 || GetUserRole === 5 || GetUserRole === 9"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.connectingRequestDashboard')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/Providers" v-if="(GetUserRole == 1 || GetUserRole == 9)"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.providers')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/ProvidersFinance" v-if="GetUserRole == 6"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.providersFinance')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/Shorts" v-if="GetUserRole == 2|| GetUserRole == 5"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.shorts')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/Scams" v-if="GetUserRole == 3|| GetUserRole == 4||GetUserRole == 1|| GetUserRole == 9"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.ScamManagement')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/RejectionReason" v-if="GetUserRole == 1|| GetUserRole == 9"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.RejectionReason')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/Invoices" v-if="GetUserRole == 2|| GetUserRole == 5 || GetUserRole == 8"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.Invoices')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/BalanceInvoice" v-if="GetUserRole == 2|| GetUserRole == 5 || GetUserRole == 8"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.BalanceInvoice')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/CITCKeywords" v-if="GetUserRole == 1 || GetUserRole == 9 || GetUserRole == 3"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.CITCKeywords')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/AttachmentCategory" v-if="GetUserRole == 1 || GetUserRole == 9 || GetUserRole == 3"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.AttachmentCategory')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/UserManagement" v-if="GetUserRole == 1 || GetUserRole == 9 || GetUserRole == 3 || GetUserRole == 5"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.userManagement')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/MonitorDashboard" v-if="GetUserRole == 10"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.DashboardApprovedRequests')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/CreditLimitTransaction" v-if="GetUserRole == 2 || GetUserRole == 5 || GetUserRole == 8|| GetUserRole == 1 || GetUserRole == 9"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.CreditLimitTransaction')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/Providers/EditProvider" v-if="GetUserRole == 2 || GetUserRole == 5"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.myProfile')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/Report" v-if="GetUserRole == 1 || GetUserRole == 4 ||GetUserRole == 3 || GetUserRole == 9"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.Report')}}</a>
                        </li>
                    </router-link>
                    <li class="nav-item dropdown custom-dropdown" v-if="GetUserRole == 1 || GetUserRole == 4 ||GetUserRole == 3 ||GetUserRole == 9">
                        <a class="nav-link dropdown-toggle" :class="{'show': isReportDropdownShow}" @click="isReportDropdownShow = !isReportDropdownShow" href="javascript:void(0);" id="navbarDropdown" role="button" data-bs-toggle="dropdown" :aria-expanded="isReportDropdownShow">
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" :class="{'show': isReportDropdownShow}" aria-labelledby="navbarDropdown">
                            <li>
                                <router-link to="/ConnectivityActivationReport" @click.native="isReportDropdownShow = !isReportDropdownShow" tag="a" class="dropdown-item">
                                    {{$t('shared.connectivityActivationReport')}}
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <router-link to="/SenderConectivitySupport" v-if="GetUserRole == 6"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.SenderConnectivitySupport')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/SenderConectivitySupport" v-if="GetUserRole == 3 || GetUserRole == 4"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.senderConnectivity')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/SenderRequestSupport" v-if="GetUserRole == 6"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.senderRequestSupport')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/SupportPaymentTransaction" v-if="GetUserRole == 6"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.SupportPaymentTransaction')}}</a>
                        </li>
                    </router-link>
                    <router-link to="/SLA" v-if="GetUserRole != 0 && GetUserRole !== 10"
                                 v-slot="{ href, route, navigate, isActive, isExactActive }"
                                 custom>
                        <li class="nav-item" :class="{'active': isActive}">
                            <a :href="href" @click="navigate" class="nav-link">{{$t('shared.sla')}}</a>
                        </li>
                    </router-link>
                    <li class="nav-item">
                        <div class="line"></div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link pr-0" href="javascript:void(0);" @click="showLogOutDialog">{{$t('shared.logOut')}} <i class="fas fa-sign-out-alt"></i></a>
                    </li>
                </ul>
            </div>
        </nav>
        <router-view v-on:childinit="onChildInit" />
        <div class="layout_footer form-row justify-content-between align-items-center">
            <div class="col-auto">
                <a>
                    {{$t('shared.privacy')}}
                </a>
            </div>
            <div class="col-auto">
                <a>
                    {{$t('shared.termsOfUse')}}
                </a>
            </div>
            <div class="col-auto">
                <a>
                    {{$t('shared.copyRight')}} {{currentYear}}
                </a>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="logOutDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.logOut')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideLogOutDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <i class="fas fa-sign-out-alt"></i>
                    <p class="mt-3">
                        {{$t('shared.logOutText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideLogOutDialog">
                                {{$t('shared.no')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="logOut">{{$t('shared.yes')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="loginDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.reLogin')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideLoginDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text v-if="logInForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img :src="require('../img/User.svg')" />
                                    </span>
                                </div>
                                <input type="text" class="form-control border-left-0" v-model="userName" :placeholder="$t('loginPage.userName')" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><img :src="require('../img/Lock.svg')" /></span>
                                </div>
                                <input class="form-control border-right-0 border-left-0" v-model="password" v-bind:type="[showPassword ? 'text' : 'password']" :placeholder="$t('loginPage.password')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right">
                                        <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" @click="showPassword = !showPassword"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="errorUserCertificate">
                            <div class="form-group">
                                <label class="red_text">{{$t('shared.errorUserCertificate')}}</label>
                            </div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-text v-if="otpForm">
                    <label class="w-100 text-center text_body">
                        {{$t('loginPage.enterOtp')}} <br />
                        {{$t('loginPage.OtpReminingTime')}}: {{ countDown }}
                    </label>
                    <div class="row row_input pb-4">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control text-center" v-model="otp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center" v-if="logInForm">
                        <div class="col-auto mx-3">
                            <a class="link_login" @click="hideLoginDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary mb-2" :disabled="!isValid" @click="login">{{$t('loginPage.login')}}</button>
                        </div>
                    </div>
                    <div class="form-row justify-content-end align-items-center" v-if="otpForm">
                        <div class="col-auto mx-3">
                            <button class="btn btn-primary mb-2" :disabled="isResendValid" @click="resendOtp">{{$t('loginPage.resendOtp')}}</button>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary mb-2" :disabled="!isResendValid" @click="verfiyOTP">{{$t('loginPage.verfiyOTP')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>