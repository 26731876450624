<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <label class="header_text mb-3">
                {{$t('shared.editRequest')}}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.clientName')}}</label>
                                <input class="form-control" v-model="model.clientName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.customerType')}}</label>
                                <v-select v-model="model.customerType"
                                          :items="customerTypeList"
                                          :label="$t('shared.select')"
                                          item-text="name"
                                          item-value="id"
                                          :disabled="isAdminEdit"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.customerType !== 0">
                            <div class="form-group">
                                <label v-if="model.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                                <label v-if="model.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                <label v-if="model.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                <label v-if="model.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                <input class="form-control" v-model="model.crNumber" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.customerType === 2 ||model.customerType === 3">
                            <div class="form-group">
                                <label>{{$t('shared.enterpriseUnifiedNumber')}}</label>
                                <input class="form-control" v-model="model.enterpriseUnifiedNumber" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.representativeName')}}</label>
                                <input class="form-control" v-model="model.representativeName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.phoneNumber')}}</label>
                                <div v-if="model.customerType === 0 || model.customerType === 1 || model.customerType === 2 ||model.customerType === 3" class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text gray">
                                            +966
                                        </span>
                                    </div>
                                    <input class="form-control border-left-0" @change="isMobileNumberValid" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                </div>
                                <div v-if="model.customerType === 4" class="input-group mb-3">
                                    <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                </div>
                                <div v-if="model.customerType === 4" class="input-group mb-3">
                                    <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.durationYear')}}</label>
                                <input class="form-control" @paste="isNumberFieldPaste($event)" v-model="model.duration"
                                       @keypress="isNumberField($event)" type="text" :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.representativeEmail')}}</label>
                                <input class="form-control" v-model="model.representativeEmail" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.senderType')}}</label>
                                <v-select v-model="senderType"
                                          :items="senderTypeList"
                                          item-text="name"
                                          item-value="id"
                                          :disabled="isAdminEdit"
                                          multiple
                                          :label="$t('shared.select')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.senderName')}}</label>
                                <input class="form-control" :disabled="isAdminEdit" @paste="isSenderNameFieldPaste($event)" @keypress="isSenderNameField($event)" :maxlength="senderNameLength" v-model="model.senderName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3 pt-3">
                            <div class="form-group">
                                <v-checkbox v-model="model.isCriticalSender"
                                            :label="$t('shared.criticalSender')"></v-checkbox>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="input-group mb-3">
                                <label>{{$t('shared.expiryDate')}}</label>
                                <v-menu v-model="expiryDateFromMenu" :close-on-content-click="true" :nudge-right="40"
                                        transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.expiryDate" readonly
                                                   v-bind="attrs" ref="createdDatePicker" v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.expiryDate" no-title scrollable @input="expiryDateFromMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-md-6 col-5 pt-3">
                                    <div class="form-group">
                                        <div class="input-group mb-3 ">
                                            <input type="file" ref="fileName" hidden accept=".png, .jpg, .jpeg, .pdf, .docx" @change="onFilePicked">
                                            <input placeholder="Add Attachment" @click="onPickFile" class="form-control border-right-0 border-left" readonly v-model="selectedFileName" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 pt-3 ">
                                    <div class="form-group">
                                        <v-select v-model="attachmentType"
                                                  :items="attachmentCategoryList"
                                                  :label="$t('shared.addCategory')"
                                                  item-text="name"
                                                  item-value="id"
                                                  solo>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="input-group-prepend pt-3">
                                    <span style="padding: 4px 4px 4px 4px !important;">
                                        <button :disabled="!canAddAttachment" @click="addAttachment" class="btn btn-primary">{{$t('shared.add')}}</button>
                                    </span>
                                </div>
                                <div class="col-md-12">
                                    <label>{{$t('shared.attachment')}}</label>
                                    <ul class="list-group list_Sender scrollbar">
                                        <li style="list-style:none" class="list-group-item" v-if="getNotDeletedAttachmnet().length > 0" v-for="(item, index) in getNotDeletedAttachmnet()" :key="item.id">
                                            <lable class="box-name">{{item.fileName}}</lable>
                                            <lable class="box-name">{{item.attachmentTypeName}}</lable>
                                            <i class="far fa-trash-alt Icon_delete" @click="deleteAttachment(item.id)"></i>
                                        </li>
                                        <li class="list-group-item" v-if=" getNotDeletedAttachmnet().length === 0">
                                            {{$t('shared.noAttachments')}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-4">
                        <div class="col-md-12 mt-5">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto mx-3">
                                    <router-link :to="'/Dashboard/SenderInfo/' + id" tag="a" class="link_secondary">{{$t('shared.cancel')}}</router-link>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-success px-5 py-2" :disabled="!isValid()" @click="showEditSenderDialog">{{$t('shared.save')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="editSenderDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.editInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideEditSenderDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.editSenderInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideEditSenderDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="save">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>