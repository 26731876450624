<div class="w-100 h-100">
    <div class="row" style="padding-top: 59px;">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-header justify-content-between align-items-center">
                    <label class="card_header_text">
                        {{$t('shared.senderRequestApprovalHistory')}}
                    </label>
                    <router-link :to="'/SenderRequestSupport'" tag="a" class="mx-1">
                        <button class="btn btn-primary btn-action">
                            {{$t('shared.back')}}
                        </button>
                    </router-link>
                </div>
                <div class="card-body">
                    <template>
                        <v-data-table :headers="headers"
                                      :items="senderRequestApprovalHistory"
                                      :total-items="senderRequestPaymentTransactionsCount"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                    <td class="text-center">{{ props.item.requestStatus }}</td>
                                    <td class="text-center">{{ props.item.rejectionReason }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
</div>

