import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './SenderConectivitySupport.html';
import { SenderConectivityDetailModel } from './SenderConectivityDetailModel';
import { Toaster } from '../../Services/toast';
import { FilterModel } from './filterModel';
import { SenderConectivityStatus } from '../../shared/Enums/SenderConectivityStatus';
import { ShortModel } from '../Short/ShortModel';
import ShortService from '../Short/ShortService';
import { SenderType } from '../../shared/Enums/SenderType';
import UserService from '../../shared/userService/UserService';
import { SenderConectivityDetaillogModel } from './SenderConectivityDetaillogModel';
import SenderConectivitySupportService from './SenderConectivitySupportService';
import ProviderService from '../Provider/ProviderService';
import { SearchModel } from './searchModel';
import { DropdownModel } from '../Provider/ProviderModel';
import { SenderStatus } from '../../shared/Enums/SenderStatus';
import UserRoleService from '../../shared/userService/UserRoleService';
import SenderConectivityDetailService from '../SenderConectivityDetail/SenderConectivityDetailService';
import { Operator } from '../../shared/Enums/Operator';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        SenderConectivitySupportService,
        ShortService,
        UserService,
        ProviderService,
        UserRoleService,
        SenderConectivityDetailService,
    ],
})
export default class SenderConectivitySupport extends Vue {
    @Inject(SenderConectivitySupportService) public senderConectivityService!: SenderConectivitySupportService;
    @Inject(SenderConectivityDetailService) public senderConectivityServiceDetails!: SenderConectivityDetailService;
    @Inject(ShortService) public shortService!: ShortService;
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ type: String, default: '' })
    public senderId: string | any;

    public isModeShow: boolean = false;
    public deleteDialog: boolean = false;
    public addDialog: boolean = false;
    public logDialog: boolean = false;
    public markAsAddedDialog: boolean = false;
    public markAsDeletedDialog: boolean = false;
    public resendToOperatorDialog: boolean = false;
    public connectivitiesCount: number = 0;
    public providerOptions: DropdownModel[] = [];
    public senderOptions: any[] = [];
    public conectivityId: string = '';
    public senderType: SenderType[] = [];
    public isAlreadyExistEnabled: boolean = false;
    public User: any;
    public userRole: any;
    public pageSize: string = '10';
    public pageIndex: string = '0';
    public pagination: any = {};
    public headers: any = [
        { text: this.$t('shortPage.company'), sortable: false, value: 'operator', align: 'center' },
        { text: this.$t('shortPage.shortCode'), sortable: false, value: 'shortCodeName', align: 'center' },
        { text: this.$t('shared.senderName'), sortable: false, value: 'senderName', align: 'center' },
        { text: this.$t('shared.provider'), sortable: false, value: 'providerName', align: 'center' },
        { text: this.$t('shared.senderType'), sortable: false, value: 'senderType', align: 'center' },
        { text: this.$t('shared.createddate'), sortable: true, value: 'createdDate', align: 'center' },
        { text: this.$t('shared.lastModifiedDate'), sortable: true, value: 'lastModifiedDate', align: 'center' },
        { text: this.$t('shared.conectivityStatus'), sortable: false, value: 'status', align: 'center' },
        { text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100' },
    ];
    public changeSort(column: any) {
        if (this.pagination.sortBy === column) {
            this.pagination.descending = !this.pagination.descending;
        } else {
            this.pagination.sortBy = column;
            this.pagination.descending = false;
        }
        this.updatePaginate(this.pagination);
    }
    public senderStatusList: DropdownModel[] = [
        {
            id: SenderStatus.Approved,
            name: this.$t('dashboardPage.active').toString(),
        },
        {
            id: SenderStatus.Deactivated,
            name: this.$t('shared.Deactivated').toString(),
        },
        {
            id: SenderStatus.Expired,
            name: this.$t('shared.Expired').toString(),
        },
        {
            id: SenderStatus.Deleted,
            name: this.$t('shared.Deleted').toString(),
        },
    ];
    public senderConectivityStatusOptions: DropdownModel[] = [
        {
            id: SenderConectivityStatus.Added,
            name: this.$t('shared.added').toString(),
        },
        {
            id: SenderConectivityStatus.PendingAddition,
            name: this.$t('shared.pendingAddition').toString(),
        },
        {
            id: SenderConectivityStatus.Deleted,
            name: this.$t('shared.deleted').toString(),
        },
        {
            id: SenderConectivityStatus.PendingDeletion,
            name: this.$t('shared.pendingDeletion').toString(),
        },
        {
            id: SenderConectivityStatus.Canceled,
            name: this.$t('shared.canceled').toString(),
        },
    ];
    public companyOptions: DropdownModel[] = [
        {
            id: Operator.Zain,
            name: this.$t('shared.zain').toString(),
        },
        {
            id: Operator.Mobily,
            name: this.$t('shared.mobily').toString(),
        },
        {
            id: Operator.STC,
            name: this.$t('shared.sTC').toString(),
        },
    ];
    public operatorOptions: any[] = [];
    public senderTypeOptions: any[] = [];
    public model: SearchModel = {
        senderName: '',
        providerId: '',
        shortcode: '',
        senderConectivityStatus: 0,
        operatorCompany: 0,
        createdDateFrom: '',
        createdDateTo: '',
    };
    public connectivities: SenderConectivityDetailModel[] = [];
    public logModel: SenderConectivityDetaillogModel = {
        id: '',
        addRequest: '',
        addResponse: '',
        deleteRequest: '',
        deleteResponse: '',
    };
    public statusOptions: any[] = [
        {
            id: SenderConectivityStatus.Added,
            name: this.$t('shared.added').toString(),
        },
        {
            id: SenderConectivityStatus.Deleted,
            name: this.$t('shared.deleted').toString(),
        },
        {
            id: SenderConectivityStatus.PendingAddition,
            name: this.$t('shared.pendingAddition').toString(),
        },
        {
            id: SenderConectivityStatus.PendingDeletion,
            name: this.$t('shared.pendingDeletion').toString(),
        },
        {
            id: 0,
            name: this.$t('shared.allConnectivityStatuses').toString(),
        },
    ];
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public async created() {
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();

        const providerResponse = await this.providerService.Get('', '', '', '', '0', '10000');
        this.providerOptions = providerResponse.result as DropdownModel[];
        this.$emit('childinit', this.layoutSup);
    }
    public get isSupport(): boolean {
        return (
            this.User.details.Role === 'Support'
        );
    }
    public async mounted() {
        const option = await this.senderConectivityService.getConnectivityConfigration();
        this.isAlreadyExistEnabled = option.result.isAlreadyExistEnabled;
        let sumSenderType = 0;
        let sumSenderTypeText = '';
        this.senderType.filter((x) => x !== 1).forEach((x, index) => {
            sumSenderType += x;
            sumSenderTypeText += this.getSenderTypeText(x);
            if (this.senderType.filter((y) => y !== 1).length !== index + 1) {
                sumSenderTypeText += ' , ';
            }
        });
        if (this.senderType.filter((x) => x === 1).length !== 0) {
            this.senderTypeOptions.push({
                id: 1,
                name: this.getSenderTypeText(1),
            });
        }
        if (sumSenderType !== 0) {
            this.senderTypeOptions.push({
                id: sumSenderType,
                name: sumSenderTypeText,
            });
        }
        this.senderConectivityStatusOptions.push
        this.search();
    }

    public async search() {
        const response = await this.senderConectivityService.get(this.model.senderName, this.model.shortcode,
            this.model.providerId, this.model.senderConectivityStatus,
            this.model.operatorCompany, this.model.createdDateFrom,
            this.model.createdDateTo, this.pagination.descending, this.pagination.sortBy, this.pageIndex, this.pageSize);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.connectivities = response.result;
        this.connectivitiesCount = response.totalCount;
    }
    public async getReport() {
        const response = await this.senderConectivityService.getReport(this.model.senderName, this.model.shortcode,
            this.model.providerId, this.model.senderConectivityStatus,
            this.model.operatorCompany, this.model.createdDateFrom,
            this.model.createdDateTo,
            this.pagination.descending, this.pagination.sortBy);
        this.search();
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
    }

    public async updatePaginate(val: any) {
        this.pageIndex = (val.page - 1).toString();
        this.pageSize = val.rowsPerPage;
        const response = await this.senderConectivityService.get(this.model.senderName, this.model.shortcode,
            this.model.providerId, this.model.senderConectivityStatus,
            this.model.operatorCompany, this.model.createdDateFrom,
            this.model.createdDateTo, this.pagination.descending, this.pagination.sortBy
            , this.pageIndex, this.pageSize);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.connectivities = response.result;
        this.connectivitiesCount = response.totalCount;
    }
    public getOperatorText(operator: number) {
        switch (operator) {
            case 1:
                return this.$t('operator_Zain').toString();
            case 2:
                return this.$t('operator_STC').toString();
            case 3:
                return this.$t('operator_Mobily').toString();
            default:
                return '-';
        }
    }
    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }

    public getSenderTypeTextTable(senderType: number) {
        let senderTypeText = '';
        const senderTypeList = [];
        if ((senderType & 1) === 1) {
            senderTypeList.push(this.getSenderTypeText(1));
        }
        if ((senderType & 2) === 2) {
            senderTypeList.push(this.getSenderTypeText(2));
        }
        if ((senderType & 4) === 4) {
            senderTypeList.push(this.getSenderTypeText(4));
        }
        if ((senderType & 8) === 8) {
            senderTypeList.push(this.getSenderTypeText(8));
        }
        senderTypeText = senderTypeList.join(' / ');
        return senderTypeText;
    }

    public getConectivityStatusText(status: number) {
        switch (status) {
            case SenderConectivityStatus.PendingAddition:
                return this.$t('shared.pendingAddition').toString();
            case SenderConectivityStatus.Added:
                return this.$t('shared.added').toString();
            case SenderConectivityStatus.PendingDeletion:
                return this.$t('shared.pendingDeletion').toString();
            case SenderConectivityStatus.Deleted:
                return this.$t('shared.deleted').toString();
            case SenderConectivityStatus.Canceled:
                return this.$t('shared.canceled').toString();
            case SenderConectivityStatus.Skipped:
                return this.$t('shared.skipped').toString();
            default:
                return '-';
        }
    }
    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value1 === undefined || value2.includes(value1));
    }
    public async MarkAsAdded() {
        const response = await this.senderConectivityService.MarkAsAdded(this.conectivityId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.ConvertedSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.markAsAddedDialog = false;
    }
    public async MarkAsDeleted() {
        const response = await this.senderConectivityService.MarkAsDeleted(this.conectivityId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.ConvertedSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.markAsDeletedDialog = false;
    }
    public async resendToOperator() {
        const response = await this.senderConectivityServiceDetails.markAsPendingAddition(this.conectivityId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.resentSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.resendToOperatorDialog = false;
    }
    public async showlogDialog(id: string) {
        const response = await this.senderConectivityService.getLogDetails(id);
        this.logModel = response.result;
        this.logDialog = true;
    }
    public hidelogDialog() {
        this.logModel = {
            id: '',
            addRequest: '',
            addResponse: '',
            deleteRequest: '',
            deleteResponse: '',
        };
        this.logDialog = false;
    }
    public showMarkAsAddedDialog(id: string) {
        this.conectivityId = id;
        this.markAsAddedDialog = true;
    }
    public hideMarkAsAddedDialog() {
        this.markAsAddedDialog = false;
    }
    public showMarkAsDeletedDialog(id: string) {
        this.conectivityId = id;
        this.markAsDeletedDialog = true;
    }
    public hideMarkAsDeletedDialog() {
        this.markAsDeletedDialog = false;
    }
    public showResendToOperatorDialog(id: string) {
        this.conectivityId = id;
        this.resendToOperatorDialog = true;
    }
    public hideResendToOperatorDialog() {
        this.resendToOperatorDialog = false;
    }
    public save() {
        this.isModeShow = true;
    }
    public back() {
        this.isModeShow = false;
    }
    public get isValid(): boolean {
        return (this.model.senderName.trim() !== ''
            || this.model.providerId !== ''
            || this.model.shortcode.trim() !== ''
            || this.model.senderConectivityStatus !== 0
            || this.model.operatorCompany !== 0
            || this.model.createdDateFrom !== ''
            || this.model.createdDateTo !== '');
    }
    public get isExportValid(): boolean {
        return (this.model.operatorCompany !== 0);
    }
}
