import { Component, Vue, Prop } from 'vue-property-decorator';
import UserManagementService from '../UserManagementService';
import WithRender from './UserRegistration.html';
import { Inject } from 'vue-di-container';

import ProviderService from '../../Provider/ProviderService';
import { UserManagementModel } from '../UserManagementModel';
import ValidationService from '../../../Services/ValidationService';
import UserModel from '../../../shared/userService/Model/UserModel';
import UserService from '../../../shared/userService/UserService';
import { UserTypeDropdownModel } from '../UserTypeDropdownModel';
import { Toaster } from '../../../Services/toast';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        UserManagementService,
        ProviderService,
        UserService,
    ],
})
export default class UserRegistration extends Vue {
    @Inject(UserService) public userService!: UserService;
    @Inject(UserManagementService) public userManagementService!: UserManagementService;
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(ValidationService) public validationService!: ValidationService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ type: String, default: 'Add' })
    public mode: string | any;

    @Prop({ default: '' })
    public id!: string;
    public UserRole: string = '';
    public addUserDialog: boolean = false;
    public editUserDialog: boolean = false;
    public type: number = 0;
    public typeOptions: UserTypeDropdownModel[] = [
        {
            id: 1,
            name: 'Admin',
        },
        {
            id: 2,
            name: 'Provider User',
        },
        {
            id: 3,
            name: 'CITC Admin',
        },
        {
            id: 4,
            name: 'CITC User',
        },
        {
            id: 5,
            name: 'Provider Admin',
        },
        {
            id: 6,
            name: 'Support',
        },
        {
            id: 7,
            name: 'Service',
        },
        {
            id: 8,
            name: 'Finance',
        },
        {
            id: 10,
            name: 'Monitor',
        },
        {
            id: 11,
            name: 'CST Tag Name',
        },
    ];
    public superAdmin: UserTypeDropdownModel = {
        id: 9,
        name: 'Super Admin',
    };

    public provider: number = 0;

    public providerOptions: any[] = [];

    public userInfo!: UserModel;

    public user: UserManagementModel = {
        id: '',
        email: '',
        mobileNumber: '',
        operatorName: '',
        providerId: '',
        providerName: '',
        roleId: 0,
        roleName: '',
        username: '',
        isLocked: false,
    };

    public created() {
        this.$emit('childinit', this.layoutSup);
    }

    public async mounted() {
        this.userInfo = await this.userService.getUser();
        this.UserRole = this.userInfo.details.Role;
        if (this.isEditMode) {
            this.user = await this.userManagementService.GeById(this.id);
        }
        if (this.userInfo.details.Role === 'Provider Admin') {
            this.typeOptions = [
                {
                    id: 2,
                    name: 'Provider User',
                },
                {
                    id: 5,
                    name: 'Provider Admin',
                },
            ];
        }
        if (this.userInfo.details.Role === 'CITC Admin') {
            this.typeOptions = [
                {
                    id: 3,
                    name: 'CITC Admin',
                },
                {
                    id: 4,
                    name: 'CITC User',
                },
            ];
        }
        if (this.userInfo.details.Role === 'Super Admin') {
            this.typeOptions.push(this.superAdmin);
        }
        const response = await this.providerService.Get('', '', '', '', '0', '10000');
        this.providerOptions = response.result;
    }
    public isMobileNumberValid() {
        if (!this.user.mobileNumber.startsWith('5')) {
            Toaster.error(this.$t('shared.InvalidMobileNumber').toString());
        }
    }

    public hideEditUserDialog() {
        this.editUserDialog = false;
    }
    public hideAddUserDialog() {
        this.addUserDialog = false;
    }
    public showEditUser() {
        this.editUserDialog = true;
    }
    public showAddUser() {
        this.addUserDialog = true;
    }
    public get isEditMode() {
        return (this.mode === 'Edit');
    }
    public async addUser() {
        this.user.username.trim();
        this.user.email.trim();
        this.user.mobileNumber.trim();
        const response = await this.userManagementService.Post(this.user);
        if (response.isSuccess) {
            this.$router.push({ name: 'UserManagement' });
            Toaster.success(this.$t('shared.AddUsersuccessfully').toString());
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hideAddUserDialog();
    }
    public async editUser() {
        this.user.email.trim();
        this.user.mobileNumber.trim();
        const response = await this.userManagementService.Put(this.user);
        if (response.isSuccess) {
            this.$router.push({ name: 'UserManagement' });
            Toaster.success(this.$t('userManagementPage.EditUserSuccessfully').toString());
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hideEditUserDialog();
    }

    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }

    public onChangeType() {
        const isEdit = this.isEditMode;
        if (!isEdit) {
            this.user.providerId = '';
        }
    }

    public get isValid(): boolean {
        return (
            (this.isEditMode || (!this.isEditMode && this.user.username.trim() !== ''))
            && (this.isEditMode || (!this.isEditMode && this.user.email.trim() !== ''))
            && (this.isEditMode || (!this.isEditMode &&
                this.validationService.isValidEmail(this.user.email.trim())))
            && this.user.mobileNumber.trim() !== ''
            && this.user.mobileNumber.trim().length === 9
            && this.user.mobileNumber.startsWith('5')
            && (this.isEditMode || (!this.isEditMode && this.user.roleId !== 0))
            && (this.isEditMode || (!this.isEditMode &&
                (this.user.roleId !== 2 || (this.user.roleId === 2 &&
                    this.user.providerId !== ''))))
            && (this.isEditMode || (!this.isEditMode &&
                (this.user.roleId !== 5 || (this.user.roleId === 5 &&
                    this.user.providerId !== ''))))
            && (this.isEditMode || (!this.isEditMode &&
                (this.user.roleId !== 7 || (this.user.roleId === 7 &&
                    this.user.providerId !== ''))))
        );
    }
}
