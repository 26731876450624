import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import ValidationService from '../../Services/ValidationService';
import WithRender from './AddRequest.html';
import { Inject } from 'vue-di-container';
import { RequestModel } from './RequestModel';
import { AttachmentModel } from './AttachmentModel';
import AddRequestService from './AddRequestService';
import { Toaster } from '../../Services/toast';
import { DropdownModel, ProviderModel } from '../Provider/ProviderModel';
import { SenderType } from '../../shared/Enums/SenderType';
import { CITCKeywordsModel } from '../CITCKeywords/CITCKeywordsModel';
import CITCKeywordsService from '../CITCKeywords/CITCKeywordsService';
import ProviderService from '../Provider/ProviderService';
import UserService from '../../shared/userService/UserService';
import UserModel from '../../shared/userService/Model/UserModel';
import { AttachmentCategoryModel } from './AttachmentCategoryModel';
import AttachmentCategoryService from '../AttachmentCategory/AttachmentCategoryService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        AddRequestService,
        CITCKeywordsService,
        ProviderService,
        AttachmentCategoryService
    ],
})
export default class AddRequest extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(AddRequestService) public addRequestService!: AddRequestService;
    @Inject(CITCKeywordsService) public cITCKeywordsService!: CITCKeywordsService;
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(AttachmentCategoryService) public attachmentCategoryService!: AttachmentCategoryService;
    @Inject(UserService) public userService!: UserService;
    @Prop({ type: Boolean, default: false })
    @Ref('fileName') fileNameRef!: HTMLInputElement;

    public layoutSup: boolean | any;
    public stepNumber: number = 1;
    public providerId: string = '';
    public senderName: string = '';
    public typeFile: number = 0;
    public senderNameLength: number = 11;
    public selectedFileName: string = '';
    public senderType: number[] = [];
    public keywordsList: CITCKeywordsModel[] = [];
    public attachmentCategoryList: AttachmentCategoryModel[] = [];
    public deletedAttachmentCategoryList: AttachmentCategoryModel[] = [];
    public sendersList: string[] = [];
    public submitRequestdialog: boolean = false;
    public isCriticalSender: boolean = false;
    public senderNameId: number = 0;
    public minDate: Date = new Date();
    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            ProviderId: '',
            TenantId: '',
            Role: '',
            ChangePasswordRequired: '',
        },
    };
    public provider: ProviderModel = {
        name: '',
        id: '',
        createdDate: null,
        crNumber: '',
        accreditationNumber: '',
        email: '',
        taxNumber: '',
        hasInternational: false,
        country: '',
        city: '',
        district: '',
        streetName: '',
        buildingNumber: '',
        postalCode: '',
        vatNumber: '',
        additionalNumber: '',
        otherIdentifier: '',
        balance: 0,
        creditLimitAmount: 0,
        bankAccountNumber: '',
        expireDate: '',
        state: '',
    };
    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];
    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];
    public zainEmail: string = '';
    public STCEmail: string = '';
    public MobilyEmail: string = '';
    public model: RequestModel = {
        duration: '1',
        startDate: '',
        clientName: '',
        customerType: 0,
        crNumber: '',
        enterpriseUnifiedNumber: '',
        representativeEmail: '',
        representativeMobile: '',
        representativeName: '',
        senderNames: [],
        senderRequestAttachments: [],
        requestType: 1,
        comment: '',
        expiryDate: '',
    };
    public attachmentType: number = 0;
    public currentAttachment: AttachmentModel =
        {
            id: '',
            fileName: '',
            fileContent: '',
            attachmentType: 0,
            attachmentTypeName: '',
            isDeleted: false,
        };
    public async created() {
        this.$emit('childinit', this.layoutSup);
        const keywordsListdata = await this.cITCKeywordsService.getAll();
        this.keywordsList = keywordsListdata.result;
        this.user = await this.userService.getUser();
        this.providerId = this.user.details.ProviderId;
        this.provider = await this.providerService.GetById(this.providerId);
        if (!this.provider.hasInternational) {
            this.customerTypeList.splice(3, 1);
        }
        const attachmentCategoryData = await this.attachmentCategoryService.getAll();
        this.attachmentCategoryList = attachmentCategoryData.result;
    }
    public isMobileNumberValid() {
        if (!this.model.representativeMobile.startsWith('5')) {
            Toaster.error(this.$t('shared.InvalidMobileNumber').toString());
        }
    }

    public next() {
        if (this.validateAttachmnet()) {
            if (this.stepNumber === 1 && this.senderName !== '') {
                this.AddSender();
            }
            this.stepNumber = this.stepNumber + 1;
        }
    }
    public validateAttachmnet(): boolean {
        if (this.model.senderRequestAttachments.filter((x) => x.attachmentType === 1).length === 0) {
            Toaster.error(this.$t('shared.canNotAddArequestWithoutADelegationLetter').toString());
            return false;
        } else if (this.model.customerType === 3 &&
            this.model.senderRequestAttachments.filter((x) => x.attachmentType === 2).length === 0) {
            Toaster.error(this.$t('shared.canNotAddArequestWithoutCommercialRegistrationNumber').toString());
            return false;
        }
        else if (this.model.customerType === 2 &&
            this.model.senderRequestAttachments.filter((x) => x.attachmentType === 4).length === 0) {
            Toaster.error(this.$t('shared.canNotAddArequestWithoutHRSDLicenseNumber').toString());
            return false;
        }
        return true;
    }

    public back() {
        this.stepNumber = this.stepNumber - 1;
    }

    @Watch('model.customerType', { deep: true })
    public onchangeCustomerType() {
        this.model.crNumber = '';
        this.model.enterpriseUnifiedNumber = '';
        switch (this.model.customerType) {
            case 1:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                    {
                        id: SenderType.Warning,
                        name: this.$t('shared.warning').toString(),
                    },
                ];
                this.senderType = [];
                this.senderName = '';
                this.model.senderNames = [];
                break;
            case 2:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                this.senderType = [];
                this.senderName = '';
                this.model.senderNames = [];
                break;
            case 3:
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                this.senderType = [];
                this.senderName = '';
                this.model.senderNames = [];
                break;
            case 4:
                this.senderTypeList = [
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                ];
                this.senderType = [SenderType.Service];
                this.senderName = '';
                this.model.senderNames = [];
                break;
        }
    }

    @Watch('senderType', { deep: true })
    public onchangeSenderType() {
        this.senderName = '';
        if (this.senderType.includes(SenderType.Advertisement)) {
            this.senderNameLength = 8;
        } else if (this.senderType.includes(SenderType.Service) && this.model.customerType === 4) {
            this.senderNameLength = 9;
        } else {
            this.senderNameLength = 11;
        }
    }
    public showsubmitRequestDialog(id: string) {
        this.submitRequestdialog = true;
    }
    public hidesubmitRequestDialog() {
        this.submitRequestdialog = false;
    }
    public async submitRequest() {
        const response = await this.addRequestService.post(this.model);
        if (response.isSuccess) {
            this.stepNumber = 3;
            this.addRequestService.addRequestEmail(response.result);
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hidesubmitRequestDialog();
    }

    public async newRequest() {
        await this.reset();
        this.stepNumber = 1;
    }
    public addAttachment() {
        this.currentAttachment.attachmentType = this.attachmentType;
        this.currentAttachment.attachmentTypeName = this.attachmentCategoryList.filter(x => x.id === this.attachmentType)[0].name;
        this.model.senderRequestAttachments.push(this.currentAttachment);
        this.currentAttachment =
        {
            id: '',
            fileName: '',
            fileContent: '',
            attachmentType: 0,
            attachmentTypeName: '',
            isDeleted: false,
        };
        var category = this.attachmentCategoryList.find(obj => obj.id === this.attachmentType);
        if (category != undefined) {
            this.deletedAttachmentCategoryList.push(category);
        }
        const index = this.attachmentCategoryList.findIndex(obj => obj.id === this.attachmentType);
        if (index !== -1) {
            this.attachmentCategoryList.splice(index, 1);
        }
        this.attachmentType = 0;
        this.selectedFileName = '';
        this.fileNameRef.value = '';
    }

    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }

    public isSenderNameField(val: KeyboardEvent) {
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(val.key)) {
            val.preventDefault();
        }
        const oneSpase = / +/g;
        if (this.senderName.endsWith(' ')) {
            if (oneSpase.test(val.key)) {
                val.preventDefault();
            }
        }
    }

    public isSenderNameFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(paste)) {
            ev.preventDefault();
        }
        const twoSpaces = /  +/g;
        if (twoSpaces.test(paste)) {
            ev.preventDefault();
        }
    }

    public get isValidSenderName(): boolean {
        return (this.senderName.trim() !== '' && this.senderName.length <= 11 && this.senderType.length !== 0);
    }
    public get canAddAttachment(): boolean {
        return (this.selectedFileName !== '' && this.attachmentType !== 0);
    }

    public AddSender() {
        const senderTypeSum = this.senderType.reduce((a, b) => a + b, 0);
        if (this.keywordsList.filter((b) => this.senderName.trim().toLowerCase() === b.name.toLowerCase()).length > 0) {
            Toaster.error(this.$t('shared.senderNameShouldNotBeOneOfTheKeywords').toString());
            return;
        }
        if (this.senderName.trim().length < 2) {
            Toaster.error(this.$t('shared.senderNameShouldbe2OrmoreChar').toString());
            return;
        }
        if (/^\d+$/.test(this.senderName.trim())) {
            Toaster.error(this.$t('shared.senderNameShouldbenumbersandChar').toString());
            return;
        }
        if (this.model.senderNames.filter((b) => {
            return (this.senderName.toLowerCase() === b.senderName.toLowerCase());
        }).length === 0) {
            if (this.senderName.trim().slice(-3).toLocaleLowerCase() !== '-ad' &&
                this.senderName.trim().slice(-2).toLocaleLowerCase() !== '-i') {
                this.model.senderNames.push({
                    senderName: this.senderName.trim(),
                    senderType: senderTypeSum,
                    isCriticalSender: this.isCriticalSender
                });
            } else {
                Toaster.error(this.$t('shared.errorSenderNameEnd').toString());
            }
        } else {
            Toaster.error(this.$t('shared.errorSenderNameDuplicate').toString());
        }
        this.senderName = '';
        this.senderType = [];
        this.isCriticalSender = false;
    }

    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }

    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }

    public deleteSender(id: number) {
        this.model.senderNames.splice(id, 1);
    }
    public deleteAttachment(id: number) {
        var requestAttachment = this.model.senderRequestAttachments[id];
        if (requestAttachment != undefined) {
            const requestAttachmentType = requestAttachment.attachmentType;
            var element = this.deletedAttachmentCategoryList.find(at => at.id == requestAttachmentType);
            if (element != undefined) {
                this.attachmentCategoryList.push(element);
            }
            var index = this.deletedAttachmentCategoryList.findIndex(at => at.id == requestAttachmentType);
            this.deletedAttachmentCategoryList.splice(index, 1);
            this.model.senderRequestAttachments.splice(id, 1);
        }
    }

    public onPickFile() {
        (this.$refs.fileName as any).click();
    }

    public onFilePicked(event: any) {
        const files: any[] = event.target.files;
        Array.prototype.forEach.call(files, (file, index) => {
            if (file.size > 3000000) {
                Toaster.error(this.$t('shared.fileSizeExceedsTheLimit').toString());
                this.fileNameRef.value = '';
            } else {
                this.selectedFileName = file.name;
                const reader = new FileReader();
                reader.onloadend = (fileData: any) => {
                    this.currentAttachment.fileContent = fileData.target.result.split(',')[1];
                    this.currentAttachment.fileName = file.name;
                };
                reader.readAsDataURL(file);
            }
        });
    }

    public get isValidStep(): boolean {
        if (this.stepNumber === 1) {
            return (
                this.model.clientName.trim() !== '' &&
                this.model.representativeName.trim() !== '' &&
                this.model.customerType !== 0 &&
                this.model.crNumber !== '' &&
                (this.model.enterpriseUnifiedNumber.trim() !== ''
                    || this.model.customerType === 1 || this.model.customerType === 4) &&
                this.model.representativeMobile !== '' &&
                (this.model.customerType === 4 ||
                    this.model.representativeMobile.length === 9) &&
                (this.model.customerType === 4 ||
                    this.model.representativeMobile.startsWith('5')) &&
                this.model.representativeEmail !== '' &&
                this.validationService.isValidEmail(this.model.representativeEmail) &&
                this.model.duration.toString().trim() !== '' &&
                this.model.duration.toString().trim() !== '0' &&
                this.model.senderNames.length !== 0 &&
                this.model.expiryDate !== "" &&
                new Date(this.model.expiryDate) > this.minDate
            );
        }
        return true;
    }

    public async reset() {
        this.model = {
            duration: '1',
            startDate: '',
            clientName: '',
            crNumber: '',
            enterpriseUnifiedNumber: '',
            customerType: 0,
            representativeEmail: '',
            representativeMobile: '',
            representativeName: '',
            senderNames: [],
            senderRequestAttachments: [],
            requestType: 1,
            comment: '',
            expiryDate: '',
        };
        this.senderType = [];
        const attachmentCategoryData = await this.attachmentCategoryService.getAll();
        this.attachmentCategoryList = attachmentCategoryData.result;
    }
}
