<div class="w-100 h-100">
    <div class="row" style="padding-top: 56px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.senderRequestSupport')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.senderName')}}</label>
                                <input class="form-control" v-model="senderName" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" style="padding: 10px;">
                        <div class="form-row justify-content-end align-items-center">
                            <div class="col-auto pull-left">
                                <button class="btn btn-primary btn-action"
                                        @click="search" :disabled="!isValid">
                                    {{$t('shared.apply')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br /> <br />
    <div class="row" v-for="sender in senderSupportDetailslist">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('dashboardPage.senderName')}}</label>
                            <h4 class="user_info_txt">{{sender.senderName}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.senderId')}}</label>
                            <h4 class="user_info_txt">{{sender.id}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.senderType')}}</label>
                            <h4 class="user_info_txt">{{sender.senderType}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.clientName')}}</label>
                            <h4 class="user_info_txt">{{sender.clientName}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.customerType')}}</label>
                            <h4 class="user_info_txt">{{sender.customerType}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0" v-if="sender.customerTypeValue === 1">{{$t('shared.budgetNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="sender.customerTypeValue === 2">{{$t('shared.licenseNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="sender.customerTypeValue === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="sender.customerTypeValue === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                            <h4 class="user_info_txt">{{sender.crNumber}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.senderStatus')}}</label>
                            <h4 class="user_info_txt">{{sender.senderStatus}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.createdDate')}}</label>
                            <h4 class="user_info_txt">{{sender.createdDate}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.lastModifiedDate')}}</label>
                            <h4 class="user_info_txt">{{sender.lastModifiedDate}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.durationYear')}}</label>
                            <h4 class="user_info_txt">{{sender.duration}}</h4>
                        </div>
                        <div class="col-sm-4 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.enterpriseUnifiedNumber')}}</label>
                            <h4 class="user_info_txt">{{sender.enterpriseUnifiedNumber}}</h4>
                        </div>
                        <div class="col-md-4 pt-4">
                           
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.durationYear')}}</label>
                                <input class="form-control" v-model="sender.newSenderDuration" type="number" min="1"/>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.status')}}</label>
                                <v-autocomplete v-model="sender.newSenderStatus"
                                                :items="senderStatusOptions"
                                                item-text="name"
                                                item-value="id"
                                                :label="$t('shared.selectStatus')"
                                                solo>
                                </v-autocomplete>
                            </div>
                        </div>
                        <div class="col-md-4 pt-4">
                            <div class="col-auto pull-left">
                                <button class="btn btn-primary btn-action"
                                        @click="showEditSenderDialog(sender.id,sender.newSenderStatus,sender.newSenderDuration)" :disabled="!isValidEdit(sender.newSenderStatus,sender.newSenderDuration)">
                                    {{$t('shared.apply')}}
                                </button>
                            </div>
                        </div>
                    </div>
                   
                    <template>
                        <v-data-table :headers="headers"
                                      :items="sender.requestModel"
                                      hide-actions
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.id}}</td>
                                    <td class="text-center">{{ props.item.providerName}}</td>
                                    <td class="text-center">{{ props.item.requestStatus }}</td>
                                    <td class="text-center">{{ props.item.requestType }}</td>
                                    <td class="text-center">{{ props.item.startDate }}</td>
                                    <td class="text-center">{{ props.item.duration }}</td>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                    <td class="text-center">{{ props.item.lastModifiedDate }}</td>
                                    <td class="text-center">{{props.item.changedProperty}} </td>
                                    <td class="text-center">
                                        <i @click="getSenderRequestApprovalHistory(props.item.id)" class="mx-5 fas fa-info-circle Icon_Info"></i>
                                    </td>
                                    <td class="text-center">
                                        <i @click="getSenderRequestPaymentTransactions(props.item.id)" class="mx-5 fas fa-info-circle Icon_Info"></i>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="editSenderDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.edit')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hidedSenderDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.editSenderStatusInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hidedSenderDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="save">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>