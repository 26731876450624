import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './RenewRegisterSender.html';
import { Inject } from 'vue-di-container';
import { GetRenewSenderModel } from './GetRenewSenderModel';
import ValidationService from '../../../../Services/ValidationService';
import { SenderType } from '../../../../shared/Enums/SenderType';
import { DropdownModel } from '../../../Provider/ProviderModel';
import { SenderModel } from '../../../Senders/SenderModel';
import { Toaster } from '../../../../Services/toast';
import RenewRegisterSenderService from './RenewRegisterSenderService';
import { GetRenewSenderRequestModel } from './GetRenewSenderRequestModel';
import { RenewRequestModel } from './RenewRequestModel';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        RenewRegisterSenderService,
    ],
})
export default class RenewRegisterSender extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(RenewRegisterSenderService) public renewRegisterSenderService!: RenewRegisterSenderService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ default: 0 })
    public id!: string;
    public showSenderInfo: boolean = false;
    public senderName: string = '';
    public typeFile: number = 0;
    public senderNameLength: number = 11;
    public senderType: SenderType[] = [];
    public renewRequestModel: RenewRequestModel = {
        senderId: '',
        duration: 0,
    };
    public getRenewSenderModel: GetRenewSenderModel = {
        senderId: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        customerType: 0,
        representativeEmail: '',
        representativeMobile: '',
        representativeName: '',
    };
    public enterpriseUnifiedNumberDisabled: boolean = true;
    public renewRequestDialog: boolean = false;
    public extendRequestDialog: boolean = false;
    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];
    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];
    public durationList: DropdownModel[] = [
        {
            id: 1,
            name: '1',
        },
        {
            id: 2,
            name: '2',
        },
        {
            id: 3,
            name: '3',
        },
        {
            id: 4,
            name: '4',
        },
        {
            id: 5,
            name: '5',
        },
    ];
    public model: SenderModel = {
        id: '',
        duration: '',
        senderType: 0,
        newSenderType: 0,
        senderStatus: 0,
        startDate: '',
        senderId: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        providerName: '',
        customerType: 0,
        representativeEmail: '',
        representativeMobile: '',
        representativeName: '',
        senderRequestAttachments: [],
        senderName: '',
        requestStatus: 0,
        lastRejectionReason: '',
        remainingDays: 0,
        isAlreadyExist: false,
        totalDays: 0,
        comment: '',
        isCriticalSender: false,
        tenantId: '',
        isScamSender: false,
        expiryDate: '',
    };
    public getModel: GetRenewSenderRequestModel = {
        senderName: ''
    };
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async mounted() {
    }

    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }
    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
    public isSenderNameField(val: KeyboardEvent) {
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(val.key)) {
            val.preventDefault();
        }
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public isSenderNameFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(paste)) {
            ev.preventDefault();
        }
    }
    public get isValid(): boolean {
        return (this.renewRequestModel.duration.toString().trim() !== ''
            && this.renewRequestModel.duration !== 0);
    }
    public showExtendRequestDialog(id: string) {
        this.extendRequestDialog = true;
    }
    public hideExtendRequestDialog() {
        this.extendRequestDialog = false;
    }
    public PreparePay(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        }
        this.$router.push({ name: 'Payment', params: { ids: ids.join(',') } });
    }
    public async saveExtendRequest() {
        this.renewRequestModel.senderId = this.getRenewSenderModel.senderId;
        const response = await this.renewRegisterSenderService.AddExtendRequest(this.renewRequestModel);
        if (response.isSuccess) {
            Toaster.success(this.$t('shared.extendRequestedSuccessfully').toString());
            this.hideExtendRequestDialog();
            //this.PreparePay(response.result)
        } else {
            Toaster.error(response.errorMessage);
            this.hideExtendRequestDialog();
            this.$router.push({ name: 'ExtendDurationRequests' });
        }
    }
    public get isValidApply(): boolean {
        return (this.getModel.senderName.trim() !== '');
    }
    public async apply() {
        const response = await this.renewRegisterSenderService
            .GetSenderForRenew(this.getModel);
        if (response.isSuccess) {
            this.getRenewSenderModel = response.result;
            this.showSenderInfo = true;
        } else {
            Toaster.error(response.errorMessage);
            this.showSenderInfo = false;
        }
    }
}
